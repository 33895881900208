import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import jwt from "jwt-decode";
import React from "react";

import { AddJob } from "../../Api/Calls";
import Button from "../../Components/Button";
import MutatingDots from "../../Components/MutatingDots";

type Props = {
  setOpen: (open: boolean) => void;
  setJobs: (jobs: any) => void;
  open: boolean;
};
export default function JobDrawer({ setJobs, setOpen, open }: Props) {
  const token = localStorage.getItem("token");
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = jwt(token);
  const [title, setTitle] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);

  const addJobs = async () => {
    setLoading(true);
    const { rows } = await AddJob(user.id, title, desc, price, date);
    setJobs(rows);
    setOpen(false);
    setLoading(false);
  };

  const style = {
    zoom: 0.9,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style}>
        <div
          style={{
            padding: 16,
            width: 350,
          }}
        >
          <div
            style={{
              fontWeight: 500,
              fontSize: 26,
              paddingBottom: 8,
            }}
          >
            Create a Job
          </div>
          <div
            style={{
              fontSize: 20,
              paddingBottom: 16,
            }}
          >
            Here you can post a tailored request for your special event, and our
            business members will be alerted to see if they can fulfill your
            request
          </div>
          <div
            style={{
              paddingBottom: 16,
            }}
          >
            <TextField
              disabled={loading}
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              id="outlined-basic"
              label="Job Title"
              variant="outlined"
            />
            <OutlinedInput
              disabled={loading}
              fullWidth
              placeholder="Estimated Price"
              style={{
                marginTop: 16,
              }}
              onChange={(e) => setPrice(e.target.value)}
              margin="none"
              id="outlined-adornment-amount"
              startAdornment={
                <InputAdornment position="start">£</InputAdornment>
              }
            />
          </div>
          <div
            style={{
              paddingBottom: 16,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                disabled={loading}
                disablePast
                label="Expected Date"
                inputFormat="DD/MM/YYYY"
                value={date}
                onChange={setDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <TextField
            disabled={loading}
            style={{
              marginBottom: 8,
            }}
            multiline
            rows={4}
            fullWidth
            onChange={(e) => setDesc(e.target.value)}
            id="outlined-multiline-static"
            label="Description"
            variant="outlined"
          />
          <Button
            name={
              loading ? (
                <div
                  style={{
                    position: "absolute",
                    zoom: 0.4,
                    left: "25rem",
                    bottom: 79,
                  }}
                >
                  <MutatingDots />
                </div>
              ) : (
                "Add Job"
              )
            }
            onClick={loading ? () => null : () => addJobs()}
          />
        </div>
      </Box>
    </Modal>
  );
}
