import React from 'react';
import { Gallery } from 'react-grid-gallery';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Text from '../../Components/Text/BaseText';
import ImageCaching from '../../Utilities/ImageCaching'
import MutatingDots from '../../Components/MutatingDots';

export default function ImageGallery({ images }: any) {
  const [loading, setLoading] = React.useState(true)
  const loadImages = async () => {
    const cacheImages = images.map((image:any) => image.src)
    await ImageCaching(cacheImages)
    setLoading(false)
  }
  React.useEffect(() => {
    loadImages()
  })
  const [index, setIndex] = React.useState(-1);
  const handleClick = (index: number, item: any) => setIndex(index);
  if(loading) {
    return (
      <div style={{
        display: 'flex',
        width: '100%',
        height:'100%',
        alignItems:'center',
        justifyContent:'center'
      }}>
        <MutatingDots />
        </div>
    )
  }
  if (images === null || images === undefined || images.length === 0) {
    return (
      <Text
        style={{
          fontSize: 16,
        }}
      >
        No images uploaded by the user
      </Text>
    );
  }
  return (
    <>
      <Gallery
        rowHeight={220}
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <Lightbox
        slides={images}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </>
  );
}
