import {
  faFileLines,
  faRectangleXmark,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import { GetImagesForService } from '../../Api/Calls';
import useWindowDimensions from '../../Utilities/WindowDetection';
import Enquire from './Enquire';
import Information from './Information';

const fadeIn = keyframes`
0% {
    filter: brightness(0);
  }
 100% {
    filter: brightness(1);
  }
`;

const fadeOut = keyframes`
  0% {
    filter: brightness(1);
  }
  100% {
     filter: brightness(0);
  }
`;

const style = {
  zIndex: 9999,
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'black',
  border: '2px solid #ffffff0',
  //   boxShadow: 24,
  //   pt: 2,
  //   px: 4,
  //   pb: 3,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,

  //   padding: 2,
};

const Tab = styled.div<{
  $selected: number;
  $value: number;
}>`
  cursor: pointer;
  padding: 16px;
  width: 100%;
  background-color: ${(props) =>
    props.$selected === props.$value ? `lightgrey` : `darkgrey`};
  text-align: center;
  color: ${(props) => (props.$selected === props.$value ? `black` : `grey`)};
  font-weight: ${(props) => (props.$selected === props.$value ? `400` : `200`)};
  border-bottom: ${(props) =>
    props.$selected === props.$value
      ? `4px solid purple`
      : `4px solid darkgrey`};
`;

const Button = styled.div`
  cursor: pointer;
  position: absolute;
  top: 80px;
  right: 20px;
  z-index: 9999;
  border-radius: 9999px;
  width: 120px;
  background-color: white;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

type Props = {
  open: boolean;
  onClose: () => void;
  service: any;
};
export default function ServiceDetails({ service, open, onClose }: Props) {
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(0);
  const getData = async () => {
    setActiveTab(0);
    setLoading(true);
    if (service?.id) {
      const data = await GetImagesForService(service.id);
      if (data?.rows) {
        const arrayImages = data.rows.map((row: any) => row.url);
        setImages(arrayImages);
        setLoading(false);
      }
    }
    setLoading(false)
  };

  React.useEffect(() => {
    getData();
  }, [service]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeTab === 1) {
        return null;
      }
      if (images.length > 0 && activeIndex >= images.length - 1) {
        return setActiveIndex(0);
      }
      return setActiveIndex(activeIndex + 1);
    }, 4500);

    return () => {
      clearTimeout(timeout);
    };
  }, [images, activeIndex]);

  const { isMobile } = useWindowDimensions();

  if (!open || service === null) {
    return null;
  }

  const { id, service_name, service_desc, subtitle } = service;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Box
          sx={{
            ...style,
            height: isMobile ? '100%' : '90vh',
            width: isMobile ? '100%' : '90%',
            overflow: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <Tab
              $selected={activeTab}
              $value={0}
              onClick={() => setActiveTab(0)}
            >
              <FontAwesomeIcon
                icon={faRectangleXmark}
                style={{ paddingRight: 8 }}
              />
              Information
            </Tab>
            <Tab
              $selected={activeTab}
              $value={1}
              onClick={() => setActiveTab(1)}
            >
              <FontAwesomeIcon icon={faFileLines} style={{ paddingRight: 8 }} />
              Enquire
            </Tab>
          </div>
          <Button onClick={onClose}>
            <FontAwesomeIcon
              icon={faRectangleXmark}
              style={{
                borderTopRightRadius: 8,
                color: 'black',
              }}
            />
            <div style={{ pointerEvents: 'none' }}>Close</div>
          </Button>

          {activeTab === 0 && <Information service={service} images={images} />}
          {activeTab === 1 && <Enquire />}
        </Box>
      )}
    </Modal>
  );
}
