import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

import { getServicesForBusiness } from '../../Api/Calls';
import Dashboard from '../../Containers/Dashboard';
import DisplayModal from './DisplayModal';

export default function ColumnSelectorDisabledGrid() {
  const [services, setServices] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const getServices = async () => {
    const userId = "19"
    const servicesForBusiness = await getServicesForBusiness(userId)
    setServices(servicesForBusiness.rows ? servicesForBusiness.rows : [])
    setLoading(false)
  }

  React.useEffect(() => {
    setLoading(true)
    getServices()
  }, [])


  if (loading) {
    return null
  }



  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'desc', headerName: 'Description' },
  ];

  const rows = services.map(({ id, service_name, service_desc }) => {
    return {
      id,
      name: service_name,
      desc: service_desc,
    }
  })
  return (
    <Dashboard>
      <div
        style={{
          padding: 16,
        }}
      >
        <DisplayModal
          open={open}
          onClose={() => setOpen(false)}
        />
        <DataGrid
          disableRowSelectionOnClick
          onRowClick={() => setOpen(true)}
          sx={{
            cursor: 'pointer', backgroundColor: 'white', '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          rows={rows} columns={columns}
          disableColumnSelector
          rowHeight={38}
          checkboxSelection={false}
        />
      </div>
    </Dashboard>
  )
}
