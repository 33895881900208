import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import * as React from 'react'

export default function MultilineTextFields() {
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': {
          color: 'white',
          background: 'black',
          borderRadius: 2,
          border: `2px solid #46709e`,
          marginTop: 2,
          width: '25ch',
          padding: 2,
        },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        inputProps={{ style: { color: 'white    ' } }}
        placeholder="Additional Information"
        id="standard-multiline-static"
        multiline
        rows={10}
        variant="standard"
      />
    </Box>
  )
}
