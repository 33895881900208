 const cachedImages = async (imageArray:any) => {
    const promises = await imageArray.map((image:any) => {
     return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = () => resolve(); 
        img.onerror = () => reject(new Error(`Failed to load image: ${image}`)); 
      })
    })
    await Promise.all(promises)
  }

  export default cachedImages