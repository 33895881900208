import {
  faBars,
  faCalendar,
  faGear,
  faHammer,
  faHome,
  faList,
  faRightFromBracket,
  faVideo,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import * as React from 'react';
import { fadeInUp } from 'react-animations';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styledComponent, { keyframes } from 'styled-components';

import useWindowDimensions from '../Utilities/WindowDetection';

const bounceAnimation = keyframes`${fadeInUp}`;

const BouncyDiv = styledComponent.div`
  animation: 0.5s ${bounceAnimation};
`;

const drawerWidth = 190;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
type Props = {
  children: React.ReactNode;
};
export default function MiniDrawer({ children }: Props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const admin = Number(localStorage.getItem('admin')) === 1;
  const open = useSelector((state: any) => state.Dashboard.openMenu);
  const { darkMode } = useSelector((state: any) => state.Config);

  const path = location.pathname;
  const navigate = useNavigate();

  const menuItems = admin
    ? [
      {
        name: 'Services',
        icon: faList,
        path: '/admin/services',
      },
      {
        name: 'Reels',
        icon: faVideo,
        path: '/admin/reels',
      },
      {
        name: 'Settings',
        icon: faGear,
        path: '/settings',
      },
      {
        name: 'Log-out',
        icon: faRightFromBracket,
        path: '/',
      },
    ]
    : [
      {
        name: 'Home',
        icon: faHome,
        path: '/home',
      },
      {
        name: 'Reels',
        icon: faVideo,
        path: '/reels',
      },
      {
        name: 'Events',
        icon: faCalendar,
        path: '/events',
      },
      {
        name: 'Jobs',
        icon: faHammer,
        path: '/jobs',
      },
      {
        name: 'Settings',
        icon: faGear,
        path: '/settings',
      },
      {
        name: 'Log-out',
        icon: faRightFromBracket,
        path: '/',
      },
    ];
  const { isMobile, screenWidth, screenHeight } = useWindowDimensions();
  if (isMobile) {
    return (
      <>
        {open && (
          <BouncyDiv>
            <div
              style={{
                position: 'absolute',
                backgroundImage: 'linear-gradient(#1c1c2b, #1c1c2b)',
                minWidth: '100vw',
                minHeight: '100vh',
                zIndex: 9999,
              }}
            >
              <FontAwesomeIcon
                onClick={() => dispatch.Dashboard.setMenu(false)}
                icon={faXmark}
                style={{ padding: 16, fontSize: 32, color: 'white' }}
                color="black"
              />
              {menuItems.map((item) => (
                <div
                  role="presentation"
                  onClick={() => {
                    if (item.path === '/') {
                      localStorage.clear();
                      navigate(item.path);
                      dispatch.Dashboard.setMenu(false);
                    }
                    navigate(item.path);
                    dispatch.Dashboard.setMenu(false);
                  }}
                  style={{
                    paddingTop: 12,
                    paddingBottom: 12,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    backgroundColor:
                      path === item.path ? '#ff00001c' : 'transparent',
                  }}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    color={path === item.path ? '	#baffc9' : '#bae1ff'}
                    style={{
                      margin: 16,
                      borderRadius: 16,
                      fontSize: 22,
                      display: 'flex',
                      alignSelf: 'center',
                    }}
                  />
                  <div
                    style={{
                      color: 'white',
                      paddingTop: 4,
                      fontSize: 14,
                      fontWeight: path === item.path ? 500 : 300,
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
          </BouncyDiv>
        )}
        <div
          style={{
            position: open ? 'fixed' : 'unset',
            opacity: open ? 0 : 1,
            minWidth: '100vw',
            minHeight: '100vh',
            backgroundImage: darkMode
              ? `linear-gradient(#1c1c2b, #1c1c2b)`
              : `linear-gradient(#d1dfff, white)`,
          }}
        >
          <div
            style={{
              zIndex: 1,
            }}
          >
            <div
              style={{
                backgroundImage: `linear-gradient(rgb(0 0 21), #1c1c2b)`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
              }}
            >
              <FontAwesomeIcon
                onClick={() => dispatch.Dashboard.setMenu(true)}
                icon={faBars}
                style={{
                  paddingLeft: 10,
                  fontSize: 32,
                  width: '10%',
                  color: 'white',
                }}
                color="black"
              />
              <div
                style={{
                  paddingTop: 6,
                  display: 'flex',
                }}
              >
                <h1
                  style={{
                    color: 'white',
                    paddingRight: 4,
                  }}
                >
                  P
                </h1>
                <h1
                  style={{
                    color: 'red',
                    fontSize: 25,
                  }}
                >
                  4
                </h1>
              </div>
              <div style={{ width: '10%' }} />
            </div>
            {children}
          </div>
        </div>
      </>
    );
  }
  return (
    <div
      style={{
        overflow: 'scroll',
        position: 'fixed',
        left: 90,
        background: darkMode
          ? `linear-gradient(109.6deg, rgb(36, 45, 57) 11.2%, rgb(16, 37, 60) 51.2%, rgb(0, 0, 0) 98.6%)`
          : `linear-gradient(109.6deg, rgb(245, 239, 249) 30.1%, rgb(207, 211, 236) 100.2%)`,
        fontSize: 30,
      }}
    >
      <Drawer variant="permanent" open={open}>
        <div
          style={{
            border: `1px solid white`,
            position: 'absolute',
            display: 'flex',
            top: 14,
            zIndex: 9999,
            left: 14,
            backgroundColor: 'black',
            borderRadius: 9999,
            width: 100,
            height: 60,
          }}
        >
          <h1
            style={{
              paddingTop: 8,
              paddingLeft: 25,
              color: 'white',
              fontSize: 35,
            }}
          >
            P
          </h1>
          <h1
            style={{
              paddingTop: 8,
              paddingLeft: 2,
              color: 'red',
              fontSize: 18,
            }}
          >
            4
          </h1>
        </div>
        <div
          style={{
            backgroundImage: darkMode
              ? `linear-gradient(#31315d, #6c6c8b)`
              : `linear-gradient(#afbcdc, #d1dfff)`,

            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {menuItems.map((item) => (
            <div
              role="presentation"
              onClick={() => {
                if (item.path === '/') {
                  localStorage.clear();
                  navigate(item.path);
                }
                navigate(item.path);
              }}
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                backgroundColor:
                  path === item.path ? '#ff00001c' : 'transparent',
              }}
            >
              <FontAwesomeIcon
                icon={item.icon}
                color={
                  path === item.path
                    ? darkMode
                      ? '#7af2cb'
                      : 'purple'
                    : darkMode
                      ? 'white'
                      : 'black'
                }
                style={{
                  borderRadius: 16,
                  fontSize: 16,
                  display: 'flex',
                  alignSelf: 'center',
                }}
              />
              <div
                style={{
                  color: darkMode ? 'white' : 'black',
                  paddingTop: 4,
                  fontSize: 14,
                  fontWeight: path === item.path ? 500 : 300,
                }}
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </Drawer>
      <div
        style={{
          width: `calc(100vw - 90px)`,
        }}
      >
        <div
          style={{
            minHeight: '100%',
            height: '100vh',
            zIndex: 1,

          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
