import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { getServicesForBusiness } from '../../Api/Calls'
import MutatingDots from '../../Components/MutatingDots'
import Text from '../../Components/Text/BaseText'
import Dashboard from '../../Containers/Dashboard'
import DeviceQuery from '../../Utilities/DeviceQuery'
import useWindowDimensions from '../../Utilities/WindowDetection'
import FollowButton from './Follow'
import About from './Tabs/About'
import Gallery from './Tabs/Gallery'
import Reels from './Tabs/Reels'
import Services from './Tabs/Services'
import TabMenu from './Tabs/TabMenu'

const Container = styled.div`
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  @media (${DeviceQuery.mobile}) {
    flex-direction: column;
  }
`

const TopCover = styled.div.attrs((props: { background: string }) => props)`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 250px;
  background-image: ${(props) => `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  filter: brightness(80%);
  @media (${DeviceQuery.mobile}) {
    background-size: cover;
    border-bottom-right-radius: 0px;
  }
`

const ProfilePicture = styled.div.attrs((props: { profile: string }) => props)`
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border: 2px solid grey;
  border-top-left-radius: 36px;
  border-bottom-right-radius: 36px;
  z-index: 1;
  position: absolute;
  top: -6rem;
  width: 200px;
  height: 200px;
  background-image: ${(props) => `url(${props.profile})`};
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-size: cover;
  @media (${DeviceQuery.mobile}) {
    width: 175px;
    height: 175px;
  }
`

const Loader = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  @media (${DeviceQuery.tablet}) {
    zoom: 1;
    width: 100%;
    overflow: hidden;
    position: fixed;
    height: 100vh;
  }
`

const LeftPayne = styled.div.attrs((props: { darkMode: boolean }) => props)`
  width: 20vw;
  min-width: 280px;
  min-height: 100vh;
  background-image: ${(props) =>
    props.darkMode
      ? `linear-gradient(to right, #99376f, #803474)`
      : `linear-gradient(to right, #376099, #345f80)`};
  @media (${DeviceQuery.tablet}) {
    zoom: 0.8;
  }
  @media (${DeviceQuery.mobile}) {
    zoom: 1;
    width: 100%;
    min-height: unset;
    height: 460px;
  }
`

const LeftPaneContent = styled.div`
  padding-top: 110px;
  @media (${DeviceQuery.mobile}) {
    padding-top: 90px;
  }
`

const RightPayne = styled.div.attrs((props: { darkMode: boolean }) => props)`
  min-height: 100vh;
  width: calc(100% - 5rem);
  background-image: ${(props) =>
    props.darkMode
      ? `linear-gradient(#0b1822, #18122d)`
      : `linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%)`};

  @media (${DeviceQuery.mobile}) {
    width: 100%;
  }
`

const Padding = styled.div`
  overflow: hidden;
  padding: 24px;
  @media (${DeviceQuery.mobile}) {
    padding: 10px;
  }
`

export default function BusinessProfile() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isMobile } = useWindowDimensions()
  const {
    state: {
      business: {
        id,
        userId,
        background,
        image: businessImage,
        name,
        profile,
        type,
        rating,
        subtitle,
      },
    },
  } = location
  const { darkMode } = useSelector((state: any) => state.Config)
  const [loading, loadingServices] = React.useState(true)
  const [services, setServices] = React.useState([])
  const [tab, setTab] = React.useState(0)

  const getServices = async () => {
    const servicesForBusiness = await getServicesForBusiness(userId)
    setServices(servicesForBusiness.rows ? servicesForBusiness.rows : [])
    loadingServices(false)
  }

  React.useEffect(() => {
    loadingServices(true)
    getServices()
  }, [])

  React.useEffect(() => {
    dispatch.ViewedProfiles.addProfile(location.state.business)
    loadingServices(true)
    getServices()
  }, [])

  if (loading) {
    return (
      <Loader>
        <MutatingDots />
      </Loader>
    )
  }

  return (
    <Dashboard>
      <TopCover background={background}>
        <FollowButton businessId={userId} />
      </TopCover>
      <Container>
        <LeftPayne darkMode={darkMode}>
          <LeftPaneContent>
            <Text
              style={{
                textAlign: 'center',
                fontSize: isMobile ? 24 : 30,
                padding: 12,
                color: 'white',
              }}
            >
              {name}
            </Text>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 14,
                fontWeight: 200,
                color: '#FFDFD3',
              }}
            >
              {type}
            </Text>
          </LeftPaneContent>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <ProfilePicture profile={businessImage} />
          </div>
        </LeftPayne>
        <RightPayne darkMode={darkMode}>
          <TabMenu tab={tab} setTab={setTab} />
          <Padding>
            {tab === 0 && <About profile={profile} tab={0} />}
            {tab === 1 && <Gallery id={userId} />}
            {tab === 2 && <Reels id={userId} />}
            {tab === 3 && <Services services={services} />}
          </Padding>
        </RightPayne>
      </Container>
    </Dashboard>
  )
}
