import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import Services from './AdminScreens/Services';
import LogoAnimationShort from './Components/LogoAnimationShort';
import ScrollToTop from './Components/ScrollToTop';
import { persistor, store } from './Rematch/Store';
import Business from './Screens/BusinessProfile';
import Events from './Screens/Events';
import Home from './Screens/Home';
import Jobs from './Screens/Jobs';
import Login from './Screens/Login/Login';
import Reels from './Screens/Reels';
import ResetPassword from './Screens/ResetPassword';
import Search from './Screens/Search';
import Results from './Screens/Search/Results';
import Settings from './Screens/Settings';

import './Translations/i18n';

const loading = () => (
  <div
    style={{
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100vh',
      width: '100vw',
      overflow: 'hidden',
      backgroundColor: '#0e0f1e',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 16,
        borderRadius: 10,
        textAlign: 'center',
      }}
    >
      <div
        style={{
          paddingBottom: '5rem',
        }}
      >
        <LogoAnimationShort interval={1500} />
      </div>
    </div>
  </div>
);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={loading()} persistor={persistor}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/reels" element={<Reels />} />
            <Route path="/search" element={<Search />} />
            <Route path="/search-results" element={<Results />} />
            <Route path="/events" element={<Events />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/business" element={<Business />} />
            <Route path="/settings" element={<Settings />} />
            <Route
              path="/users/reset-password/:id/:token"
              element={<ResetPassword />}
            />
            <Route path="/admin/services" element={<Services />} />
            <Route path="/admin/reels" element={<Services />} />

          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
