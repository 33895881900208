import jwt from 'jwt-decode';

import { Headers, METHOD, URL } from './Constants';


const token = localStorage.getItem('token');
let user = '';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (token) {
  user = jwt(token).user;
}

export const AttemptLogin = async (email, password) => {
  try {
    const authoriseUrl = `${URL}/users/auth`;
    const response = await fetch(authoriseUrl, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        email,
        password,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const CreateAccount = async (
  name,
  email,
  number,
  password,
  country,
  accountType,
) => {
  try {
    const createAccount = `${URL}/users/create`;
    const response = await fetch(createAccount, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        name,
        email,
        number,
        password,
        country,
        accountType: accountType === 'personal' ? 0 : 1,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const setEventDate = async (userId, date, type) => {
  try {
    const setDate = `${URL}/events/set`;
    const response = await fetch(setDate, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        date,
        type,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getEventDate = async (userId) => {
  try {
    const getDate = `${URL}/events/get`;
    const response = await fetch(getDate, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getRandom5Businesses = async () => {
  try {
    const getBusinesses = `${URL}/business/random_5`;
    const response = await fetch(getBusinesses, {
      method: METHOD.GET,
      headers: Headers(),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const BusinessSearch = async (keyword) => {
  try {
    const search = `${URL}/business/search`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        keyword,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const BusinessSearchWithinArea = async (lat, long, distance) => {
  try {
    const search = `${URL}/business/local`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        latitude: lat,
        longitude: long,
        distance,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getServicesForBusiness = async (id) => {
  try {
    const search = `${URL}/services/available`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const isBusinessFavourited = async (userId, businessId) => {
  try {
    const search = `${URL}/business/is_favourite`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: userId,
        businessId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const favouritiseBusiness = async (userId, businessId) => {
  try {
    const search = `${URL}/business/favourite`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: userId,
        businessId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const unfavouritiseBusiness = async (userId, businessId) => {
  try {
    const search = `${URL}/business/unfavourite`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: userId,
        businessId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getFavouriteBusinesses = async (userId) => {
  try {
    const search = `${URL}/business/favourite_businesses`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getAdvancedSearchViaBusiness = async (
  lat,
  long,
  keyword,
  distance,
) => {
  try {
    const search = `${URL}/business/advanced_search_business`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        latitude: lat,
        longitude: long,
        keyword: `%${keyword}%`,
        distance,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getAdvancedSearchViaServices = async (
  lat,
  long,
  keyword,
  distance,
) => {
  try {
    const search = `${URL}/services/advanced_search_services`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        latitude: lat,
        longitude: long,
        keyword: `%${keyword}%`,
        distance,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getEvents = async (userId) => {
  try {
    const search = `${URL}/events/get`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getJobs = async (userId) => {
  try {
    const search = `${URL}/jobs/get`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const addEvent = async (userId, title, dateFrom, dateTo, icon) => {
  try {
    const search = `${URL}/events/set`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        title,
        dateFrom,
        dateTo,
        icon,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const addJob = async (date, title) => {
  try {
    const search = `${URL}/jobs/set`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId: user.id,
        title,
        date,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const updateEvent = async (id, title, dateFrom, dateTo, icon) => {
  try {
    const search = `${URL}/events/update`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id,
        userId: user.id,
        title,
        dateFrom,
        dateTo,
        icon,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const UpdateJob = async (
  jobId,
  date,
  title,
  description,
  expectedDate,
  pricing,
) => {
  try {
    const search = `${URL}/jobs/update`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: jobId.toString(),
        userId: user.id.toString(),
        title,
        date,
        description,
        expectedDate,
        pricing,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const deleteEvent = async (eventId) => {
  console.log(user.id);
  try {
    const search = `${URL}/events/delete_event`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId: user.id,
        eventId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const deleteJob = async (jobId) => {
  try {
    const search = `${URL}/jobs/delete`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        jobId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const deleteTaskForEvent = async (id, eventId) => {
  try {
    const search = `${URL}/events/delete_task`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id,
        userId: user.id,
        eventId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const addTaskToEvent = async (eventId, name, date, time) => {
  try {
    const search = `${URL}/events/add_task`;
    console.log('user id', user.id);
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId: user.id,
        eventId,
        name,
        date,
        time,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};
export const getTasksForEvent = async (eventId) => {
  try {
    const search = `${URL}/events/get_tasks`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId: user.id,
        eventId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const updateTaskForEvent = async (eventId, name, date, time, taskId) => {
  try {
    const search = `${URL}/events/update_task`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId: user.id,
        eventId,
        name,
        date,
        time,
        taskId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const isServiceFavourited = async (userId, serviceId) => {
  try {
    const search = `${URL}/services/is_favourite`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: userId,
        serviceId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const favouritiseService = async (userId, serviceId) => {
  try {
    const search = `${URL}/services/favourite`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: userId,
        serviceId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const unfavouritiseService = async (userId, serviceId) => {
  try {
    const search = `${URL}/services/unfavourite`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: userId,
        serviceId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getFavouriteService = async (userId) => {
  try {
    const search = `${URL}/services/favourite_services`;
    const response = await fetch(search, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getUserDetails = async (userId) => {
  try {
    const url = `${URL}/users/get_user_details`;
    const response = await fetch(url, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const setAvatar = async (url, userId) => {
  try {
    const addAvatarUrl = `${URL}/users/add_avatar`;
    const response = await fetch(addAvatarUrl, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        url,
        id: userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

/**
 * business accounts
 */
export const getUsersBusiness = async (userId) => {
  try {
    const getBusiness = `${URL}/business/get_business`;
    const response = await fetch(getBusiness, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getSavedBusinessIds = async () => {
  try {
    const getBusiness = `${URL}/business/saved_ids`;
    const response = await fetch(getBusiness, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: user.id,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const insertBusiness = async (userId, name, subtitle, profile) => {
  try {
    const insert = `${URL}/business/insert_business`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        name,
        subtitle,
        profile,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const updateBusiness = async (field, value, userId) => {
  try {
    const insert = `${URL}/business/update`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        field,
        value,
        userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getGalleryImages = async (userId) => {
  try {
    const get = `${URL}/gallery/get`;
    const response = await fetch(get, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const uploadImages = async (userId, values) => {
  try {
    const insert = `${URL}/gallery/insert`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        values,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const DeleteImages = async (userId, values) => {
  try {
    const insert = `${URL}/gallery/delete`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        values,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getReels = async (userId) => {
  try {
    const get = `${URL}/reels/get`;
    const response = await fetch(get, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const insertReels = async (userId, values) => {
  try {
    const insert = `${URL}/reels/insert`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        values,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const deleteReels = async (userId, values) => {
  try {
    const insert = `${URL}/reels/delete`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        values,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const addService = async (
  userId,
  name,
  subtitle,
  desc,
  image,
  priceFrom,
  priceTo,
  priceType,
  restrictions,
  extras,
  youtube,
) => {
  try {
    const insert = `${URL}/services/insert`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        name,
        subtitle,
        desc,
        image,
        priceFrom,
        priceTo,
        priceType,
        restrictions,
        extras,
        youtube,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const addNewService = async (
  userId,
  name,
  subtitle,
  desc,
  image,
  priceFrom,
  priceTo,
  priceType,
  restrictions,
  extras,
  youtube,
) => {
  try {
    const insert = `${URL}/services/add`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        name,
        subtitle,
        desc,
        image,
        priceFrom,
        priceTo,
        priceType,
        restrictions,
        extras,
        youtube,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const EditAService = async (
  name,
  subtitle,
  desc,
  image,
  priceFrom,
  priceTo,
  priceType,
  restrictions,
  extras,
  youtube,
  userId,
  serviceId,
) => {
  try {
    console.log(
      name,
      subtitle,
      desc,
      image,
      priceFrom,
      priceTo,
      priceType,
      restrictions,
      extras,
      youtube,
      serviceId,
      userId,
    );
    const insert = `${URL}/services/update`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        name,
        subtitle,
        desc,
        image,
        priceFrom,
        priceTo,
        priceType,
        restrictions,
        extras,
        youtube,
        serviceId,
        userId,
      }),
    });
    const data = await response.json();
    console.log(data);
    return data;
  } catch (err) {
    return err;
  }
};

export const getServices = async (userId) => {
  try {
    const insert = `${URL}/services/get`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const DeleteServices = async (userId, serviceId) => {
  try {
    const insert = `${URL}/services/delete`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        serviceId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const uploadServiceImages = async (userId, values) => {
  try {
    const insert = `${URL}/services/add_images`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        values,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const GetServiceById = async (userId, serviceId) => {
  try {
    const insert = `${URL}/services/get_by_id`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        serviceId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const GetServiceImagesById = async (userId, serviceId) => {
  try {
    const insert = `${URL}/services/get_image_by_id`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        serviceId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const GetImagesForService = async (serviceId) => {
  try {
    const insert = `${URL}/services/get_service_images`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        serviceId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const updateServiceField = async (field, value, serviceId, userId) => {
  try {
    const insert = `${URL}/services//update_service_field`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        field,
        value,
        serviceId,
        userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const DeleteServiceImageURLs = async (urls) => {
  try {
    const insert = `${URL}/services/delete_images`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        urls,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const GetRandomServiceImages = async (userId) => {
  try {
    const insert = `${URL}/services/get_random_5_images`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const GetBusinessReels = async () => {
  try {
    const insert = `${URL}/reels/get_business_reels`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const ResetPassword = async (email) => {
  try {
    const insert = `${URL}/users/forgot-password`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        email,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const AddJob = async (userId, title, desc, price, date) => {
  try {
    const insert = `${URL}/jobs/set`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        title,
        date: new Date(),
        description: desc,
        pricing: price,
        expectedDate: date,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const GetJobs = async (userId) => {
  try {
    const insert = `${URL}/jobs/get`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const DeleteJob = async (userId, jobId) => {
  try {
    const insert = `${URL}/jobs/delete`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        userId,
        jobId,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const updatePass = async (validator, password) => {
  try {
    const insert = `${URL}/users/reset-password`;
    const response = await fetch(insert, {
      method: METHOD.POST,
      headers: Headers(),
      body: JSON.stringify({
        id: user.id,
        token: validator,
        password,
      }),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};
