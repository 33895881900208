import React from 'react';
import jwt from 'jwt-decode';
import styled from 'styled-components';
import Dashboard from '../../Containers/Dashboard';
import TopPane from './TopPane';
import BottomPane from './BottomPane';
import EaseIn from '../../Components/EaseIn';
import {
  getRandom5Businesses,
  getFavouriteBusinesses,
  getUserDetails,
  getEvents,
  GetJobs,
} from '../../Api/Calls';
import DeviceQuery from '../../Utilities/DeviceQuery';
import MutatingDots from '../../Components/MutatingDots';
import ImageCaching from '../../Utilities/ImageCaching'
const Loader = styled.div`
  display: flex;
  width: 90vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  @media (${DeviceQuery.tablet}) {
    zoom: 1;
    width: 100%;
    overflow: hidden;
    position: fixed;
    height: 90vh;
  }
`;

export default function Discover() {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = jwt(token);
  const [events, setEvents] = React.useState([]);
  const [savedBusinesses, setSavedBusinesses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [userName, setName] = React.useState('');
  const [randomBusinesses, setRandomBusinesses] = React.useState([]);
  const [jobs, setJobs] = React.useState(0);


  const getData = async () => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { rows } = await getFavouriteBusinesses(user.id);
    const { rows: eventRows } = await getEvents(user.id);
    const userDetails = await getUserDetails(user.id);
    const businesses = await getRandom5Businesses();
  
    const businessImages = businesses.rows.map((b: any) => b.image)
    await ImageCaching(businessImages)
    const { rows: currentJobs } = await GetJobs(user.id);
    setJobs(currentJobs === null ? 0 : currentJobs.length);
    setRandomBusinesses(businesses.rows);
    setEvents(eventRows);
    setSavedBusinesses(rows);
    setName(userDetails[0].name);
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <Dashboard>
        <Loader>
          <MutatingDots />
        </Loader>
      </Dashboard>
    );
  }
  return (
    <Dashboard>
      <EaseIn>
        <div
          style={{
            padding: 16,
            height: '100%',
            width: '100%',
          }}
        >
          <TopPane
            jobs={jobs}
            events={events}
            savedBusinesses={savedBusinesses}
            userName={userName}
          />
          <BottomPane randomBusinesses={randomBusinesses} />
        </div>
      </EaseIn>
    </Dashboard>
  );
}
