import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import DeviceQuery from '../../Utilities/DeviceQuery';
import InspireYourself from './InspireYourself.json';
import { BusinessSearch } from '../../Api/Calls';
import HeaderText from '../../Components/Text/HeaderText';
import ImageCaching from '../../Utilities/ImageCaching'
const rotate = keyframes`
  from {
    filter: brightness(0);
    opacity: 0.15;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  @media (${DeviceQuery.mobile}) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  height: 100%;
  width: 40%;
  @media (${DeviceQuery.mobile}) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  height: 100%;
  width: 60%;
  @media (${DeviceQuery.mobile}) {
    width: 100%;
  }
`;

const RightContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  @media (${DeviceQuery.mobile}) {
    display: unset;
  }
`;

const LeftContent = styled.div.attrs((props: { image: string }) => props)`
  cursor: pointer;
  position: relative;
  height: calc((100vh - 15.5rem));
  width: calc(100% - 5px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `url(${props.image})`};
  animation: ${rotate} 1.5s;

  @media (${DeviceQuery.mobile}) {
    height: 350px;
    width: 100%;
  }
`;

const TextContent = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  color: white;
`;

const Card = styled.div.attrs((props: { image: string }) => props)`
  cursor: pointer;
  position: relative;
  height: calc((100vh / 2) - 7.9rem);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `url(${props.image})`};
  @media (${DeviceQuery.mobile}) {
    margin-bottom: 8px;
  }
`;

type Props = {
  randomBusinesses: any;
};

export default function BottomPane({ randomBusinesses }: Props) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [inspire, setInspire] = React.useState([]);
  const navigate = useNavigate();

  const onSearch = async (input: string) => {
    const res = await BusinessSearch(input);
    navigate('/search-results', {
      state: { input, results: res.rows },
    });
  };

  const loadImages = async () => {
    const inspireImages =InspireYourself.map((i) => i.image)
    await ImageCaching(inspireImages)
  }
  React.useEffect(() => {
    loadImages()
    setInspire(
      InspireYourself.sort(() => Math.random() - Math.random()).slice(0, 6),
    );
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        randomBusinesses.length > 0 &&
        activeIndex >= randomBusinesses.length - 1
      ) {
        setActiveIndex(0);
      } else {
        setActiveIndex(activeIndex + 1);
      }
    }, 7500);

    return () => {
      clearTimeout(timeout);
    };
  }, [randomBusinesses, activeIndex]);

  return (
    <Container>
      <LeftContainer>
        <HeaderText>Explore New Talent</HeaderText>
        <LeftContent
          onClick={() =>
            navigate('/business', {
              state: { business: randomBusinesses[activeIndex] },
            })
          }
          key={randomBusinesses[activeIndex].id}
          image={randomBusinesses[activeIndex].image}
        >
          <div
            style={{
              paddingLeft: `12px`,
              paddingRight: `12px`,
              position: 'absolute',
              bottom: 0,
              height: 100,
              width: '100%',
              backgroundColor: '#000000db',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  paddingBottom: 4,
                  fontSize: 18,
                  color: 'white',
                }}
              >
                {randomBusinesses[activeIndex].name}
              </div>
              <TextContent>{randomBusinesses[activeIndex].profile}</TextContent>
            </div>
          </div>
        </LeftContent>
      </LeftContainer>
      <RightContainer>
        <HeaderText>Inspire Yourself</HeaderText>
        <RightContent>
          {inspire.map(({ name, profile, image }) => (
            <Card
              image={image}
              onClick={() => onSearch(name)}
            >
              <div
                style={{
                  paddingLeft: `10px`,
                  paddingRight: `10px`,
                  position: 'absolute',
                  bottom: 0,
                  height: 100,
                  width: '100%',
                  backgroundColor: '#000000db',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      paddingBottom: 4,
                      fontSize: 16,
                      color: 'white',
                    }}
                  >
                    {name}
                  </div>
                  <TextContent>{profile}</TextContent>
                </div>
              </div>
            </Card>
          ))}
        </RightContent>
      </RightContainer>
    </Container>
  );
}
