import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import jwt from 'jwt-decode';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AttemptLogin, getUserDetails, ResetPassword } from '../../Api/Calls';
import EaseIn from '../../Components/EaseIn';
import DeviceQuery from '../../Utilities/DeviceQuery';
import palette from '../../Utilities/Palette';
import { IsTokenValid } from '../../Utilities/TokenValidation';
import useWindowDimensions from '../../Utilities/WindowDetection';
import ForgottenPassword from './Components/ForgottenPassword';
import LoginDetails from './Components/LoginDetails';
import Register from './Components/Register';
import ResetPasswordEmailSent from './Components/ResetPasswordEmailSent';
import Background from './Images/Background.jpg';
import Background2 from './Images/Background2.jpg';
import Background3 from './Images/Background3.jpg';
import Background4 from './Images/Background4.jpg';
import Background5 from './Images/Background5.jpg';

const max = 4;
const min = 0;
const backgrounds = [
  Background,
  Background2,
  Background3,
  Background4,
  Background5,
];
const Root = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url(${backgrounds[
  Math.round(Math.random() * (max - min)) + min
  ]});
  background-color: rgba(0, 0, 0, 0.5); /* Tint color */
  background-blend-mode: multiply;
  background-size: cover;
  background-position: 100%;
  @media (${DeviceQuery.mobile}) {
    position: fixed;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  @media (max-width: 576px) {
    width: 100%;
    width: 100%;
    justify-content: center;
    box-shadow: unset;
    z-index: 1;
  }
  @media (max-height: 576px) {
    width: 100%;
    width: 100%;
    justify-content: center;
    box-shadow: unset;
    z-index: 1;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100vh;
  width: 96%;
  @media (max-height: 576px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 30px;
  }
  @media (max-width: 576px) {
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const LoginContainer = styled.div`
  box-shadow: 0px 0px 20px 1px black;
  min-width: 400px;
  max-width: 460px;
  min-height: 650px;
  max-height: 650px;
  width: 36%;
  height: 50%;
  background-color: ${palette.WHITE};
  border-radius: 10px;

  padding: 50px;

  @media (max-height: 576px) {
    width: 50%;
    height: 100%;
    box-shadow: unset;
    background-color: ${palette.WHITE};
    padding: 26px;
    zoom: 0.6;
    overflow-y: scroll;
  }

  @media (max-width: 820px) {
    width: 100%;
    height: 42%;
    background-color: ${palette.WHITE};
    padding: 26px;
  }

  @media (max-width: 576px) {
    zoom: 0.7;
    width: 100%;
    width: 100%;
    background-color: ${palette.WHITE};
    box-shadow: unset;
    z-index: 1;
  }
`;

export default function Login() {
  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();

  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [forgotPassword, setForgetPassword] = React.useState<boolean>(false);
  const [register, setRegister] = React.useState<boolean>(false);
  const [loading, isLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [resetPasswordEmailSent, setResetPasswordEmailSent] =
    React.useState<boolean>(false);

  const [rehydrateComplete] = React.useState(
    useSelector((state: any) => state._persist.rehydrated),
  );

  const dispatch = useDispatch();

  const onClickLogin = async () => {
    isLoading(true);
    const { token } = await AttemptLogin(email, password);
    if (token !== undefined && token.length > 0) {
      localStorage.setItem('token', token);
      dispatch.Dashboard.closeMenu();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { user } = jwt(token);
      const userDetails = await getUserDetails(user.id);
      const { business_account: business } = userDetails[0];

      localStorage.setItem('admin', business);
      navigate(business === 0 ? '/home' : '/admin/services');
    } else {
      setError(true);
      isLoading(false);
    }
  };

  const onClickRegister = () => setRegister(true);

  const navigateToLogin = () => {
    isLoading(false);
    setForgetPassword(false);
    setResetPasswordEmailSent(false);
  };

  const navigateToChangeEmailAddress = () => {
    isLoading(false);
    setForgetPassword(true);
    setResetPasswordEmailSent(false);
  };

  const navigateToEmailSent = async () => {
    await ResetPassword(email);
    isLoading(false);
    setForgetPassword(false);
    setResetPasswordEmailSent(true);
  };

  const navigateToForgottenPassword = () => setForgetPassword(true);

  const isValid = IsTokenValid();

  React.useEffect(() => {
    if (isValid && rehydrateComplete) {
      const business = Number(localStorage.getItem('admin'));
      navigate(business === 0 ? '/home' : '/admin/profile');
    }
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: 2.5,
    borderRadius: 2,
  };

  const COMING_SOON = process.env.REACT_APP_COMING_SOON === 'true';
  return (
    <Root>
      {register && (
        <Modal
          data-testid="register-modal"
          open={register}
          onClose={() => setRegister(false)}
        >
          <Box sx={{ ...style }}>
            <div
              style={{
                zoom: isMobile ? 0.6 : 0.8,
                backgroundColor: 'white',
              }}
            >
              <FontAwesomeIcon
                icon={faSquareXmark}
                color={palette.BLACK}
                style={{
                  padding: 16,
                  fontSize: 50,
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  cursor: 'pointer',
                }}
                onClick={() => setRegister(false)}
              />
              <Register
                onClose={() => setRegister(false)}
                onSuccess={() => setSuccess(true)}
              />
            </div>
          </Box>
        </Modal>
      )}
      <EaseIn>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={error}
          autoHideDuration={3000}
          onClose={() => setError(false)}
        >
          <Alert severity="error">Invalid Login/Password</Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={success}
          autoHideDuration={3000}
          onClose={() => setError(false)}
        >
          <Alert severity="success">Account created! Logging in...</Alert>
        </Snackbar>
        <Container>
          <Wrapper>
            {COMING_SOON ? null : (
              <LoginContainer>
                {!forgotPassword && !resetPasswordEmailSent && (
                  <LoginDetails
                    data-testid="login-pane"
                    loading={loading}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    onClickForgotPassword={navigateToForgottenPassword}
                    onClickLogin={onClickLogin}
                    onClickRegister={onClickRegister}
                  />
                )}
                {forgotPassword && (
                  <ForgottenPassword
                    data-testid="forgotten-password"
                    setEmail={setEmail}
                    onClickBackToLogin={navigateToLogin}
                    onClickResetPasswordEmail={navigateToEmailSent}
                  />
                )}
                {resetPasswordEmailSent && (
                  <ResetPasswordEmailSent
                    data-testid="reset-password"
                    onClickBackToLogin={navigateToLogin}
                    onClickChangeEmailAddress={navigateToChangeEmailAddress}
                  />
                )}
              </LoginContainer>
            )}
          </Wrapper>
        </Container>
      </EaseIn>
    </Root>
  );
}
