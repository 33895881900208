import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import EaseIn from '../../../Components/EaseIn';
import Text from '../../../Components/Text/BaseText';
import DeviceQuery from '../../../Utilities/DeviceQuery';
import ServiceDetails from '../ServiceDetails';

type Props = {
  services: any;
};

const ServiceImage = styled.div.attrs((props: { image: string }) => props)`
  position: relative;
  margin: 4px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;
  min-width: 290px;
  width: calc((70vw / 3) - 0.3rem);
  height: 300px;
  border: 1px solid #80808091;
  background-image: ${(props) => `url(${props.image})`};
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-size: cover;
  @media (${DeviceQuery.tablet}) {
    min-width: calc(100% - 10px);
    height: 300px;
  }
  @media (${DeviceQuery.mobile}) {
    min-width: calc(100vw - 2rem);
    height: 300px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 60vh;
  overflow: hidden;
  @media (${DeviceQuery.mobile}) {
    flex-direction: column;
    overflow: hidden;
  }
`;

export default function Services({ services }: Props) {
  const { darkMode } = useSelector((state: any) => state.Config);
  const [open, setOpen] = React.useState(false);
  const [select, setSelected] = React.useState(null);
  return (
    <EaseIn>
      <ServiceDetails
        service={select}
        open={open}
        onClose={() => {
          setOpen(false);
          setSelected(null);
        }}
      />
      <Text
        style={{
          fontSize: 32,
          paddingBottom: 8,
          fontWeight: 120,
        }}
      >
        SERVICES
      </Text>
      <Text
        style={{
          fontWeight: 300,
          fontSize: 18,
        }}
      >
        <Container>
          {services.map((service: any) => (
            <ServiceImage
              image={service.image}
              onClick={() => {
                setSelected(service);
                setOpen(true);
              }}
            >
              <div
                style={{
                  height: 90,
                  width: '100%',
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  position: 'absolute',
                  bottom: 20,
                  backgroundColor: darkMode ? '#140410e4' : '#000000b4',
                  borderTop: `2px solid ${darkMode ? `#330752` : `#2a88ccba`}`,
                  borderBottom: `2px solid ${darkMode ? `#330752` : `#2a88ccba`
                    }`,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: 350,
                    color: 'white',
                  }}
                >
                  {service.service_name}
                </Text>
                <Text
                  style={{
                    fontWeight: 200,
                    color: 'white',
                  }}
                >
                  {service.subtitle.length > 80
                    ? `${service.subtitle.substring(0, 80)}...`
                    : service.subtitle}
                </Text>
              </div>
            </ServiceImage>
          ))}
        </Container>
      </Text>
    </EaseIn>
  );
}
